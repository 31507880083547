import React, {Component} from "react";
import PropTypes from "prop-types";

class ResourceModal extends Component{

	render(){
		const {resource, isActive, onClose} = this.props;
		return (
			<div className={`modal ${isActive ? "is-active" : ""}`}>
				<div className="modal-background"></div>
				<div className="modal-content">
					{resource}
				</div>
				<button className="modal-close is-large" onClick={onClose} aria-label="close"></button>
			</div>
		)
	}

}

ResourceModal.propTypes = {
	resource : PropTypes.element,
	isActive : PropTypes.bool,
	onClose : PropTypes.func
};

export default ResourceModal;