import React, {Fragment} from "react";

const ResourceText = (props) => ((
	<Fragment>
		<h4 style={styles.teamText}>
			{props.title}
		</h4>
		<p
			style={styles.teamTextSubtitle}>
			{props.children}
		</p>
	</Fragment>
));

const styles = {
	teamText : {
		fontSize: 35,
		fontWeight: 400,
		marginBottom: 20
	},
	teamTextSubtitle : {
		marginBottom: 0,
		fontWeight: 200,
		fontSize: 17,
		textAlign: "left"
	}
};
export default ResourceText;