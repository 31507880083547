import React, { Component } from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Resource from "./pages/Resource";
import Resources from "./pages/Resources";
import Faq from "./pages/Faq";
import ComingForRadiotherapy from "./pages/resources/ComingForRadiotherapy";
import ComingForRadiotherapyBreathHold from "./pages/resources/ComingForRadiotherapyBreathHold";
import Relaxation from "./pages/resources/Relaxation";
import TechniquesSitting from "./pages/resources/TechniquesSitting";
import TechniquesLaying from "./pages/resources/TechniquesLaying";
import AdditionalResources from "./pages/AdditionalResources";
import TechniquesRadiotherapyPosition from "./pages/resources/TechniquesRadiotherapyPosition";



class Main extends Component{
	render(){
		return (
			<main>
				<Route exact path="/" component={Home} />
				<Route exact path="/about" component={About} />
				<Route exact path="/faq" component={Faq} />
				<Route exact path="/resources" component={Resources} />

				<Route exact path="/additional-resources" component={AdditionalResources} />

				<Route exact path="/resource/coming-for-radiotherapy" component={ComingForRadiotherapy} />
				<Route exact path="/resource/coming-for-radiotherapy-breath-hold" component={ComingForRadiotherapyBreathHold} />
				<Route exact path="/resource/relaxation-video-clip" component={Relaxation} />

				<Route exact path="/resource/techniques-sitting" component={TechniquesSitting} />
				<Route exact path="/resource/techniques-laying" component={TechniquesLaying} />
				<Route exact path="/resource/techniques-radiotherapy-position" component={TechniquesRadiotherapyPosition} />

			</main>
		)
	}
}

export default Main
