
import React, {Component, Fragment} from 'react';
import ReactAudioPlayer from "react-audio-player";
import {Header} from "../Header";
import TeamMember from "../elements/TeamMember";
import Resource from "../elements/Resource";
import TechniquesRadiotherapyPosition from "./resources/TechniquesRadiotherapyPosition";
import ComingForRadiotherapy from "./resources/ComingForRadiotherapy";
import ResourceModal from "../elements/ResourceModal";
import ComingForRadiotherapyBreathHold from "./resources/ComingForRadiotherapyBreathHold";
import TechniquesSitting from "./resources/TechniquesSitting";
import TechniquesLaying from "./resources/TechniquesLaying";
import Relaxation from "./resources/Relaxation";
import Footer from "../elements/Footer";
import {Animated} from "react-animated-css";





const additionalResources = [
	{
		title:  "Relaxation and the management of hypertension",
		link: "https://www.cochranelibrary.com/cdsr/doi/10.1002/14651858.CD004935.pub2/full?highlightAbstract=*relaxation%7Cwithdrawn%7Ctherapy%7Ctherapi"
	},
	{
		title: "Relaxation for depression",
		link: "https://www.cochranelibrary.com/cdsr/doi/10.1002/14651858.CD007142.pub2/full?highlightAbstract=withdrawn%7Ctherapy%7Crelax%7Ctherapi%7Crelaxation"
	},
	{
		title: "Relaxation and menopausal symptoms",
		link: "https://www.cochranelibrary.com/cdsr/doi/10.1002/14651858.CD004923.pub2/full"
	},
	{
		title: "Impact of relaxation on cognitive impairment in patients with cancer",
		link: "https://www.sciencedirect.com/science/article/pii/S2352013216301983"
	},
	{
		title: "Impact of relaxation in reducing anxiety in patients with breast cancer",
		link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3832338/"
	}
];

class Resources extends Component{
	constructor(props){
		super(props);

		this.state = {activeResource : null}
	}

	render(){
		const {activeResource} = this.state;
		const relaxationResource = (<Relaxation/>);

		return (
			<Fragment>

				{activeResource ? (
					<ResourceModal
						resource={activeResource}
						isActive
						onClose={() => this.setState({activeResource : null})}/>
				) : (<div/>)}

				<Header absolute />

			
                <section style={styles.top} className="hero is-small is-primary b2 is-bold">

<div className="hero-body" style={styles.getStartedBody}>
    <div className="columns">
        <div className="column">
            <div className="container has-text-centered">
                <div className="columns">
                    <div className="column">
                        <Animated animationIn="fadeIn">
                
                            <h1 className="title has-text-centered" style={styles.teamText}>
                                Additional Resources
                            </h1>
                        </Animated>
                        <Animated animationIn="fadeIn" animationInDelay={100}>

                            <h2 className="subtitle has-text-centered"
                                style={styles.teamTextSubtitle}>
                                Healthcare professionals interested in the benefits of relaxation may find the following links to research evidence useful for context.
                            </h2>
                        </Animated>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>



				<section className="hero is-small is-primary is-bold b1" style={{...styles.section, paddingBottom: 0}}>

					<div className="hero-body" style={styles.resources}>
						<div className="columns">
							<div className="column">
								<div className="container has-text-centered">

								
									<div className="columns is-multiline">
										{additionalResources.map(resource => (
											<div className="column is-4-desktop is-6-tablet">
												<Resource link={resource.link} title={resource.title} 
															onOpen={(resource) => this.setState({activeResource : resource})}/>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<Footer/>

			</Fragment>
		)
	}
}

const styles={
	top : {
		paddingTop: 150,
		paddingBottom: 100,
	},
	section : {
		paddingTop: 20,
		paddingBottom: 80
	},
	resources : {
		paddingTop: 80,
		paddingBottom: 80
	},
	teamText : {
		fontSize: 60,
		fontWeight: 200,
		marginBottom: 70
	},
	teamTextSubtitle : {
		fontWeight: 200,
	},
	sectionSubtitle : {
		marginBottom: 50,
		fontWeight: 200
	},
	additional: {
		fontSize: 30,
		margin: 50
	},
	audioClips : {
		fontSize : 18
	},
	audioPlayer : {
		marginRight: 25,
		marginTop: 10,
		marginBottom: 10,
		display: "inline-block"
	},

	audioLink : {
		color: "#fff",
		textDecoration: "underline"
	},

	partners : {
		backgroundColor: "#ffffff"
	}
};

export default Resources