import React, { Component } from 'react';
import Header from "./components/Header"
import Main from "./components/Main"
import Footer from "./components/elements/Footer"

import './App.sass';

class App extends Component {
	constructor(props){
		super(props);

	}

	render() {

		return (
			<div>
				<Main />
			</div>
		);
	}
}

export default App;