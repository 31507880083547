import React from "react";
import {Animated} from "react-animated-css";

const Question = (props) => {
	console.log(props);
	return (
		<Animated animationIn="fadeIn" animationInDelay={200}>
			<div style={styles.box}>
				<article className="media" style={styles.media}>

					<div className="media-content">
						<div className="content">
							<p style={styles.question}>{props.question}</p>
							<p style={styles.answer} dangerouslySetInnerHTML={props.answer}>
							</p>
						</div>
					</div>

				</article>
			</div>
		</Animated>
	)
};

const styles = {
	question : {
		fontWeight: 400,
		marginBottom: 0,
		fontSize: 17,
		color: "rgb(10,10,10)"
	},
	answer : {
		marginTop: 10,
		// color: "rgb(200,200,200)"
	},
	box : {
		borderRadius: 0,
		// backgroundColor: "rgba(0,0,0,0.3)"
	},
	media : {
		padding: "20px 0 20px 20px"
	},
	mediaLeft : {
		marginRight: 40
	}
};

export default Question