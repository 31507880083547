
import React, {Component, Fragment} from 'react';
import {Header} from "../Header";
import Question from "../elements/Question";
import Footer from "../elements/Footer";
import {Animated} from "react-animated-css";



const questions = [
	{
		question: "What happens if I am unable to hold my breath?",
		answer: "Your healthcare team will talk to you about other options they will use when planning your radiotherapy to minimise the radiation dose that is received by your heart. It is also worth noting that the risk of a heart related event following radiotherapy is very small and the benefit of radiotherapy is much greater than this small risk."
	},
	{
		question: "Are there any other relaxation techniques to choose from?",
		answer: "We would recommend you avoid using breathing related relaxation techniques as this may interfere with the breath hold technique you may be required to use for radiotherapy. We would recommend you take a look at some of the resources on the Mind website <a href='https://www.mind.org.uk/information-support/tips-for-everyday-living/relaxation/relaxation-tips/?o=6833#.XheOwyOcYcg'>here</a>."
	}
];

class Faq extends Component{
	render(){
		return (
			<Fragment>
				<Header absolute/>
				<section style={styles.top} className="hero is-small is-primary is-bold">

					<div className="hero-body" style={styles.getStartedBody}>
						<div className="columns">
							<div className="column">
								<div className="container has-text-centered">
									<div className="columns">
										<div className="column">
										<Animated animationIn="fadeIn">
									
											<h1 className="title has-text-centered" style={styles.teamText}>
												Frequently Asked Questions
											</h1>
										</Animated>
										<Animated animationIn="fadeIn" animationInDelay={100}>
											<h2 className="subtitle has-text-centered"
												style={styles.teamTextSubtitle}>
												We know you may have lots of questions about your treatment and planning and even more questions about the resources. The first people to contact are the healthcare team that are looking after you. Particularly we would recommend you contact the therapeutic radiographers (or Radiation Therapists if you live outside the UK) that are planning or giving you your treatment.											</h2>
										</Animated>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className="container" style={styles.questions}>
					<div className="columns is-multiline">
						{questions.map((question, index) => (
							<div key={question.question} className="column is-12 is-6-desktop">
								<Question
									order={index}
									question={question.question}
									answer={{__html: question.answer}}
								/>
							</div>
						))}


					</div>
				</div>
				
				<Footer />				


			</Fragment>
		)
	}
}

const styles={
	top : {
		paddingTop: 150,
		paddingBottom: 100,
		marginBottom: 70
	},
	questions : {
		marginBottom: 70
	},
	teamText : {
		fontSize: 60,
		fontWeight: 200,
		marginBottom: 70
	},
	teamTextSubtitle : {
		fontWeight: 200
	},

	partners : {
		backgroundColor: "#ffffff",
		paddingTop: 50
	}
};

export default Faq