
import React, {Component, Fragment} from 'react';
import {Header} from "../../Header";
import YouTube from "react-youtube-embed";
import NextButton from "../../elements/NextButton";
import ResourceText from "../../elements/ResourceText";
import ReactPlayer from 'react-player';

class TechniquesSitting extends Component{
	render(){
		return (
			<Fragment>


						<div className="columns">
							<div className="column">
								<div className="container">

									<div className="columns">
										<div className="column is-8-fullhd is-offset-2-fullhd">
											<div class="box" style={styles.box}>
												<div className="columns">
													<div className="column">
														<ReactPlayer url="https://cdn.respire.org.uk/preparing-3.mp4" playing controls style={{margin: "0 auto", marginBottom: 20}}/>
													</div>
												</div>
												<div className="columns">
													<div className="column">
														<ResourceText
															title= "Learning the techniques - radiotherapy position"
														>
															Finally, in this video we will talk you through how to practice the breath hold technique while lying in the position for radiotherapy.
														</ResourceText>

													</div>
												</div>
												{/*<div className="columns">*/}
												{/*	<div className="column">*/}
												{/*		<NextButton back next text="Back"/>*/}
												{/*	</div>*/}
												{/*</div>*/}
											</div>
										</div>
									</div>



								</div>
						</div>
					</div>

			</Fragment>
		)
	}
}

const styles={
	top : {
		paddingTop: 150,
		paddingBottom: 100
	},


	partners : {
		backgroundColor: "#ffffff"
	},
	box : {
		padding: 40,
		borderRadius: 0,
		overflowY: "auto",
		maxHeight: "calc(100vh - 160px)"
	}
};

export default TechniquesSitting