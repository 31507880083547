
import React, {Component, Fragment} from 'react';
import ReactAudioPlayer from "react-audio-player";
import {Header} from "../Header";
import {Link} from "react-router-dom";

import TeamMember from "../elements/TeamMember";
import Resource from "../elements/Resource";
import TechniquesRadiotherapyPosition from "./resources/TechniquesRadiotherapyPosition";
import ComingForRadiotherapy from "./resources/ComingForRadiotherapy";
import ResourceModal from "../elements/ResourceModal";
import ComingForRadiotherapyBreathHold from "./resources/ComingForRadiotherapyBreathHold";
import TechniquesSitting from "./resources/TechniquesSitting";
import TechniquesLaying from "./resources/TechniquesLaying";
import Relaxation from "./resources/Relaxation";
import Footer from "../elements/Footer";
import {Animated} from "react-animated-css";




const resources = [
	{
		title : "Coming for Radiotherapy",
		description : "This video clip shows what will happen when you attend for your first appointment in the CT scanning room.",
		resource : <ComingForRadiotherapy/>
	},
	{
		title : "Coming for Radiotherapy if you are having breath hold",
		description : "This video shows what to expect if you have been advised that you will be required to undertake breath hold during your radiotherapy",
		resource: <ComingForRadiotherapyBreathHold/>
	}
];

const preparation = [
	{
		title: "Learning the techniques - sitting",
		description: "In this video we will talk through the techniques for breath hold in a sitting position.",
		resource: <TechniquesSitting/>
	},
	{
		title: "Learning to increase the time you are able to hold your breath for",
		description: "In this video we will talk you through how to build up to holding your breath for longer.",
		resource:  <TechniquesLaying/>
	},
	{
		title : "Learning the techniques - radiotherapy position",
		description: "This video talks you through how to practice the breath hold technique while in the position for radiotherapy.",
		resource: <TechniquesRadiotherapyPosition/>
	}
];

const additionalResources = [
	{
		title:  "Relaxation and the management of hypertension",
		link: "https://www.cochranelibrary.com/cdsr/doi/10.1002/14651858.CD004935.pub2/full?highlightAbstract=*relaxation%7Cwithdrawn%7Ctherapy%7Ctherapi"
	},
	{
		title: "Relaxation for depression",
		link: "https://www.cochranelibrary.com/cdsr/doi/10.1002/14651858.CD007142.pub2/full?highlightAbstract=withdrawn%7Ctherapy%7Crelax%7Ctherapi%7Crelaxation"
	},
	{
		title: "Relaxation and menopausal symptoms",
		link: "https://www.cochranelibrary.com/cdsr/doi/10.1002/14651858.CD004923.pub2/full"
	},
	{
		title: "Impact of relaxation on cognitive impairment in patients with cancer",
		link: "https://www.sciencedirect.com/science/article/pii/S2352013216301983"
	},
	{
		title: "Impact of relaxation in reducing anxiety in patients with breast cancer",
		link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3832338/"
	}
];

class Resources extends Component{
	constructor(props){
		super(props);

		this.state = {activeResource : null}
	}

	render(){
		const {activeResource} = this.state;
		const relaxationResource = (<Relaxation/>);

		return (
			<Fragment>

				{activeResource ? (
					<ResourceModal
						resource={activeResource}
						isActive
						onClose={() => this.setState({activeResource : null})}/>
				) : (<div/>)}

				<Header absolute />


				<section style={styles.top} className="hero is-small is-primary b1 is-bold">

					<div className="hero-body" style={styles.getStartedBody}>
						<div className="columns">
							<div className="column">
								<div className="container has-text-centered">
									<div className="columns">
										<div className="column">
											<Animated animationIn="fadeIn">
									
												<h1 className="title has-text-centered" style={styles.teamText}>
													Resources
												</h1>
											</Animated>
											<Animated animationIn="fadeIn" animationInDelay={100}>

												<h2 className="subtitle has-text-centered"
													style={styles.teamTextSubtitle}>
													You will get the most out of this website if you look at the material in a particular order.

												</h2>
											</Animated>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>



				<section style={{...styles.section, paddingBottom: 10}} className="hero is-small is-primary is-bold b2">

					<div className="hero-body" style={{...styles.resources, marginBottom: 0}}>
						<div className="columns" id="relaxation">
							<div className="column">
								<div className="container has-text-centered">

									<div className="columns" style={{marginBottom: 100}}>
										<div className="column is-6 is-offset-3">
											<Animated animationIn="fadeInUp" animationInDelay={100}>	
												<Resource
													resource={relaxationResource}
													title="Relaxation video clip"
													description="One of the RESPIRE project team talks to Jane (who has had radiotherapy for breast cancer in the past) about the benefits of learning and using relaxation skills."
													onOpen={(resource) => this.setState({activeResource : relaxationResource})}
												/>
											</Animated>
										</div>
									</div>
									<Animated animationIn="fadeInUp" animationInDelay={150}>	

									<div className="columns has-text-left is-vcentered">
										<div className="column has-text-centered">
											
												<div style={styles.audioPlayer}>
														<h2 style={{marginBottom: 15}}>Relaxation clip 1</h2>
														<ReactAudioPlayer
															src="https://cdn.respire.org.uk/relaxation-1.mp3"
															controls
														/>
												</div>
												<div style={styles.audioPlayer}>

													<h2 style={{marginBottom: 15}}>Relaxation clip 1</h2>
													<ReactAudioPlayer
														src="https://cdn.respire.org.uk/relaxation-2.mp3"
														controls
													/>

												</div>
										</div>
										<div className="column is-6">
												<p
													style={styles.audioClips}>
													There are two styles of relaxation for you to listen to. You may find
													that you prefer one to the other. Audio relaxation clip 1 uses a way of
													focusing on each area of your body in turn. Audio relaxation clip 2 uses
													visualisation to encourage you to imagine a relaxing place. Feel free to
													try either method.
													You can download clip 1 <a style={styles.audioLink} href="https://cdn.respire.org.uk/relaxation-1-download.mp3">here</a> and clip 2 <a style={styles.audioLink} href="https://cdn.respire.org.uk/relaxation-2-download.mp3">here</a>.
												</p>
										</div>

									</div>
									</Animated>

								</div>
							</div>
						</div>
					</div>
				</section>


			
				<section style={styles.resources} className="hero is-small is-primary b1 is-bold">

					<div className="hero-body" style={styles.section}>

					<div className="columns is-multiline has-text-centered">
										<div className="column">
											<h1 className="subtitle additional" style={styles.additional}>
												Coming for Radiotherapy
											</h1>
										</div>

									</div>
						<div className="columns">
							<div className="column">
								<div className="container has-text-centered">

									<div className="columns is-multiline">
										{resources.map((resource, order) => (
												<div key={resource.title} className="column is-6-desktop is-6-tablet">
													<Animated animationIn="fadeInUp" animationInDelay={100 * order}>										
														<Resource
															resource={resource.resource}
															title={resource.title}
															description={resource.description}
															onOpen={(resource) => this.setState({activeResource : resource})}
														/>
													</Animated>

												</div>
										))}
									</div>






								</div>
							</div>
						</div>
					</div>
				</section>

				<section style={styles.section} className="hero is-small is-primary is-bold b2">

					<div className="hero-body" style={styles.getStartedBody}>
						<div className="columns">
							<div className="column">
								<div className="container has-text-centered">

									<div className="columns is-multiline has-text-centered">
										<div className="column">
											<h1 className="subtitle additional" style={styles.additional}>
												Preparing for using the breath hold technique
											</h1>
											<h2 className="subtitle has-text-centered"
												style={styles.sectionSubtitle}>
												Here you will find three short videos to help you practice the breath hold technique needed for your radiotherapy planning and treatment. Please view them in order.
											</h2>
										</div>
									</div>
									<div className="columns is-multiline">
										{preparation.map((resource, index) => (
											<div className="column is-4-desktop is-6-tablet" key={resource.title}>
												<Resource
													title={resource.title}
													resource={resource.resource}
													order={index + 1}
													onOpen={(resource) => this.setState({activeResource : resource})}
													description={resource.description}
												/>
											</div>
										))}
									</div>
								
								</div>
							</div>
						</div>
					</div>
				</section>


				<section style={{...styles.section, paddingBottom: 20}} className="hero is-small is-primary is-bold b1">

					<div className="hero-body" style={styles.getStartedBody}>
					<div className="columns">
										<div className="column">
	
										<h2 className="subtitle has-text-centered">
												Healthcare professionals can also view additional resources <Link to="/additional-resources" style={{textDecoration: "underline"}}>here</Link>
											</h2>

										</div>
									</div>
						</div>

						</section>

				<Footer/>

			</Fragment>
		)
	}
}

const styles={
	top : {
		paddingTop: 150,
		paddingBottom: 100,
	},
	section : {
		paddingTop: 20,
		paddingBottom: 80
	},
	resources : {
		paddingTop: 80,
		paddingBottom: 80
	},
	teamText : {
		fontSize: 60,
		fontWeight: 200,
		marginBottom: 70
	},
	teamTextSubtitle : {
		fontWeight: 200,
	},
	sectionSubtitle : {
		marginBottom: 50,
		fontWeight: 200
	},
	additional: {
		fontSize: 30,
		margin: 50
	},
	audioClips : {
		fontSize : 18
	},
	audioPlayer : {
		marginRight: 25,
		marginTop: 10,
		marginBottom: 10,
		display: "inline-block"
	},

	audioLink : {
		color: "#fff",
		textDecoration: "underline"
	},

	partners : {
		backgroundColor: "#ffffff"
	}
};

export default Resources