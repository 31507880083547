import React from "react";
import {Animated} from "react-animated-css";

const TeamMember = (props) => {

	return (
		<Animated animationIn="fadeInUp" animationInDelay={200 + (props.order * 100)}>
			<div className="box" style={styles.box}>
				<article className="media" style={styles.media}>
					<figure className="media-left" style={styles.mediaLeft}>
						<p className="image is-128x128">
							<img className="is-rounded" src={props.image} />
						</p>
					</figure>
					<div className="media-content">
						<div className="content">
								<h4 style={styles.title}>{props.name}</h4>
							<p>
								{props.bio}
							</p>
						</div>

					</div>
					<div className="media-right">
						<button className="delete"></button>
					</div>
				</article>
			</div>
		</Animated>
	)
};

const styles = {
	title : {
		fontWeight: 400
	},
	box : {
		borderRadius: 0
	},
	media : {
		padding: "20px 0 20px 20px"
	},
	mediaLeft : {
		marginRight: 40
	}
};

export default TeamMember