
import React, {Component, Fragment} from 'react';
import {Header} from "../../Header";
import YouTube from "react-youtube-embed";
import NextButton from "../../elements/NextButton";
import ResourceText from "../../elements/ResourceText";
import ReactPlayer from "react-player";

class ComingForRadiotherapyBreathHold extends Component{
	render(){
		return (
			<Fragment>

						<div className="columns">
							<div className="column">
								<div className="container">

									<div className="columns">
										<div className="column is-8-fullhd is-offset-2-fullhd">											<div class="box" style={styles.box}>
												<div className="columns">
													<div className="column">
														<ReactPlayer url="https://cdn.respire.org.uk/coming-for-radiotherapy-breath-hold.mp4" playing controls style={{margin: "0 auto", marginBottom: 20}}/>
													</div>
												</div>
												<div className="columns">
													<div className="column">
														<ResourceText
															title= "Coming for Radiotherapy if you are having breath hold"
														>
															This video shows what to expect if you have been advised that you will be required to undertake breath hold during your radiotherapy.
															<br/><br/>
															To help explain the process the video shows a number of real patient CT scan images. If you are not used to viewing these images they can be difficult to follow. Imagine if we hypothetically cut a person in half from left to right and then looked up from where the lower half of the body would have been.
															<br/><br/>
															Looking up towards the head end you would see a cross-section of the organs and body structures, this is what you see on a CT scan. As you look at the video screen the patients left will be on your right and the patient’s right will be on your left; don’t worry we won’t be testing you.
														</ResourceText>

													</div>
												</div>
												{/*<div className="columns">*/}
												{/*	<div className="column">*/}
												{/*		<NextButton resource="coming-for-radiotherapy"/>*/}
												{/*		<NextButton next back="relaxation" text="Back"/>*/}
												{/*	</div>*/}
												{/*</div>*/}
											</div>
										</div>
									</div>



								</div>
							</div>
						</div>


			</Fragment>
		)
	}
}

const styles={
	top : {
		paddingTop: 150,
		paddingBottom: 100
	},


	partners : {
		backgroundColor: "#ffffff"
	},
	box : {
		padding: 40,
		borderRadius: 0,
		overflowY: "auto",
		maxHeight: "calc(100vh - 160px)"
	}
};

export default ComingForRadiotherapyBreathHold