
import React, {Component, Fragment} from 'react';
import {Header} from "../../Header";
import YouTube from "react-youtube-embed";
import NextButton from "../../elements/NextButton";
import ResourceText from "../../elements/ResourceText";
import ReactPlayer from 'react-player';

class ComingForRadiotherapy extends Component{
	render(){
		return (
			<Fragment>
					<div className="hero-body" style={styles.getStartedBody}>
						<div className="columns">
							<div className="column">
								<div className="container">

									<div className="columns">
										<div className="column is-8-fullhd is-offset-2-fullhd">
											<div className="box" style={styles.box}>
												<div className="columns">
													<div className="column">
														<ReactPlayer url="https://cdn.respire.org.uk/coming-for-radiotherapy.mp4" playing controls style={{margin: "0 auto", marginBottom: 20}}/>
													</div>
												</div>
												<div className="columns">
													<div className="column">
														<ResourceText
															title= "Coming for Radiotherapy"
														>
															This video clip shows what will happen when you attend for your first appointment in the CT scanning room. This is the first part of your radiotherapy planning.
															<br/><br/>
															In this video we have tried to give some examples of what the machine may look like and also the position you will be asked to lie in.
															<br/><br/>
															The machines may look slightly different to the ones shown in this video as different hospitals may use different machine manufacturers. You will also notice that the patient in the video is dressed in a special modesty gown for the purposes of the video. You are likely to be positioned naked from the waist upwards for your radiotherapy planning and treatment, or you may be asked to wear a specially designed bra.

														</ResourceText>

													</div>
												</div>
												{/*<div className="columns">*/}
												{/*	<div className="column">*/}
												{/*		<NextButton next text="Next video" resource="coming-for-radiotherapy-breath-hold"/>*/}
												{/*	</div>*/}
												{/*</div>*/}
											</div>
										</div>
									</div>



								</div>
							</div>
						</div>
					</div>

			</Fragment>
		)
	}
}

const styles={
	top : {
		paddingTop: 150,
		paddingBottom: 100
	},


	partners : {
		backgroundColor: "#ffffff"
	},
	box : {
		padding: 40,
		borderRadius: 0,
		overflowY: "auto",
		maxHeight: "calc(100vh - 160px)"
	}
};

export default ComingForRadiotherapy