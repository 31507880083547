
import React, {Component, Fragment} from 'react';
import {Header} from "../Header";
import TeamMember from "../elements/TeamMember";
import {Animated} from "react-animated-css";
import Footer from "../elements/Footer";



const teamMembers = [
	{
		name: "Professor Heidi Probst",
		image: require("../../assets/images/team/heidi.png"),
		bio: "I qualified as a therapeutic radiographer in 1987 from the London Hospital (Whitechapel) and worked for fourteen years as a clinical radiographer in the National Health Service in Leeds. I moved to Sheffield Hallam University in 2001 where I work as a researcher and lecturer. My research is focussed on improving delivery of radiotherapy for breast cancer and improving the patient experience; I am the Principal Investigator for the RESPIRE project."
	},
	{
		name: "Janet Ulman",
		image: require("../../assets/images/team/janet.png"),
		bio: "I'm a research assistant on the RESPIRE project. At the moment I'm also undertaking research to investigate women's experiences of developing and living with breast or trunk lymphoedema after treatment for breast cancer. Before joining Sheffield Hallam University in 2016 I worked for many years as an occupational therapist. I have experience of delivering relaxation sessions to clients and patients and appreciate the value of developing relaxation skills for coping with challenging situations and for managing anxiety."
	},
	{
		name: "Keeley Rosbottom",
		image: require("../../assets/images/team/keeley.png"),
		bio: "I am Course leader/Senior lecturer in Radiotherapy & Oncology, I graduated in 2008 and worked at St James' Hospital in Leeds as a Radiographer, and then as a Specialist Practitioner. I joined SHU as a lecturer in April 2012. I have worked on the Support 4 All project, as a research assistant and am now part of the RESPIRE team."
	},
	{
		name: "Dr Maria Burton",
		image: require("../../assets/images/team/maria.png"),
		bio: "I am physiotherapist, lecturer and a senior researcher with significant experience and expertise in cancer, predominantly breast cancer.  As a physiotherapist I understand the challenges faced by both patients and staff in learning and teaching different breathing techniques and the need for materials to support this. I also have an awareness of the anxiety and stress patients with breast cancer experience during the treatment phase. My role has been one of facilitators along with Heidi to bring together radiotherapists and physiotherapists to produce these much needed resources."
	},
	{
		name: "Jane Barry",
		image: require("../../assets/images/team/jane.png"),
		bio: "I am a patient representative on the Respire Project. I am from a teaching background and was headteacher for 21 years and successfully took the school to outstanding twice over. After retiring in 2013, I was diagnosed with breast cancer in 2014. Since then I have been without cancer for 6 years. I have become involved and active in supporting this project as I am committed to being involved in cancer care and research."
	},
	{
		name: "Helen Clough",
		image: require("../../assets/images/team/helen.png"),
		bio: "Qualified Therapeutic radiographer for 12 years, with 3 of those being in current position as Breast Lead Advanced Practitioner at Leeds Oncology Centre. Responsible for all breast technique development within the department and introducing vDIBH to Leeds. MSc in Radiotherapy and Oncology with dissertation on vDIBH benefits and optimal uses. "
	},
	{
		name: "Mel Lindley",
		image: require("../../assets/images/team/mel.png"),
		bio: "I qualified as a Physiotherapist in 1997, working as a clinical specialist in respiratory care across the South Yorkshire region from 2000. I moved to Sheffield Hallam University in 2008 where I work as a lecturer and lead for innovations in teaching. My research is focussed on improving the quality of learning and understanding through the delivery of media-rich materials.  I provide input to the RESPIRE project in relation to the breathing techniques and instructional videos."
	},
	{
		name: "Russell Mather",
		image: require("../../assets/images/team/russ.png"),
		bio: "As a member of the Sheffield Hallam University Media & Design technical team. I work alongside academic members of staff on health related projects to create resources to support research, teaching and learning through video, animation and illustration"
	}
];

const partners = [
	{
		image:  "/images/nhs.png",
		link: "https://www.leedsth.nhs.uk/a-z-of-services/leeds-cancer-centre/your-treatment/radiotherapy/"
	},
	{
		image: "/images/bcn.png",
		link: "https://breastcancernow.org/"
	},
	{
		image: "/images/macmillan.jpg",
	},
	{
		image: "/images/brig.png",
		link: "https://heidiprobst.com/breast-radiotherapy-interest-group/",
	},
	{
		image: "/images/visionrt.png",
		link: "https://www.visionrt.com/"
	},
	{
		image: "/images/elekta.jpg",
		link: "https://www.elekta.com/"
	},
	{
		image: "/images/varian.jpg",
		link: "https://www.varian.com/products/radiotherapy/real-time-tracking-and-motion-management"
	}
];

class About extends Component{
	render(){
		return (
						<Fragment>
							<Header absolute />
							<section style={styles.top} className="hero is-small is-primary is-bold">

								<div className="hero-body" style={styles.getStartedBody}>
									<div className="columns">
										<div className="column">
											<div className="container has-text-centered">
												<div className="columns">
													<div className="column">
														<Animated animationIn="fadeIn">
															<h1 className="title has-text-centered" style={styles.teamText}>
																About us
															</h1>
														</Animated>
														<Animated animationIn="fadeIn" animationInDelay={100}>
															<h2 className="subtitle has-text-centered"
																style={styles.teamTextSubtitle}>
																We are a group of researchers, experienced healthcare technical staff and patients (with experience of breast cancer)  working at Sheffield Hallam University and Leeds Teaching Hospitals NHS Trust in the United Kingdom.
																We are striving to improve the patient experience by helping patients to be prepared for receiving their radiotherapy
															</h2>
														</Animated>
													</div>
												</div>
												<div className="columns is-multiline" style={{display: "flex", alignItems: "center"}}>
													{teamMembers.map((member, order) => (
														<div key={member.name} className="column is-12">
															<TeamMember
																order={order}
																name={member.name}
																bio={member.bio}
																image={member.image}
															/>
														</div>
													))}


												</div>


											</div>
										</div>
									</div>
								</div>
							</section>


							<section style={styles.partners}>
								<div className="hero-body">
									<div className="columns is-multiline">
										<div className="column">
											<h1 className="title has-text-centered" style={styles.teamText}>
												Our partners
											</h1>
											<div>
												<div className="container partners has-text-centered">
													{partners.map(partner => (
														<a key={partner.link} href={partner.link} style={{cursor: partner.link ? "cursor" : "default"}}>
															<img src={partner.image} />
														</a>
													))}
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>

							<Footer />				

						</Fragment>
		)
	}
}

const styles={
	top : {
		paddingTop: 150,
		paddingBottom: 100
	},
	teamText : {
		fontSize: 60,
		fontWeight: 200,
		marginBottom: 70
	},
	teamTextSubtitle : {
		marginBottom: 70,
		fontWeight: 200
	},

	partners : {
		backgroundColor: "#ffffff",
		paddingTop: 50,
		paddingBottom: 60
	}
};

export default About