import React, {Component, Fragment} from 'react';
import {Link} from "react-router-dom";
import YouTube from 'react-youtube-embed'
import TeamMember from "../elements/TeamMember";
import Header from "../Header";
import {Animated} from "react-animated-css";


class Home extends Component{
    render(){
        return (

			<Fragment>
				<Header absolute/>

				<section className="hero is-primary is-fullheight home-image">
					<div className="hero-body">
    					<div className="container">
							<div className="columns">
								<div className="column is-7-fullhd">
									<Animated animationIn="fadeInDown">
										<h1 className="title">
											Informed, Prepared
										</h1>
									</Animated>
									<Animated animationIn="fadeIn" animationInDelay={400}>
										<h1 className="title empowered">
											Empowered
										</h1>
									</Animated>

									<Animated animationIn="fadeIn" animationInDelay={600}>
										<p style={{fontSize: 18, fontWeight: 400}}>
											This website contains a series of resources developed from a partnership between healthcare professionals and patient representatives. The resources are designed for patients who have been referred for radiotherapy to the breast or chest wall following a breast cancer diagnosis. In particular, the aim of the resources is to help patients who have been diagnosed with cancer in their breast for whom it may be beneficial to learn how to hold their breath for a short time during radiotherapy. For some patients, this is known to keep the area to be treated away from sensitive structures such as the heart. If you are not expected to need to use the breath hold technique you may still find some of the resources helpful.
											<br/>
											<br/>
											Relatives and friends of patients referred for breast or chest wall radiotherapy may also find this site useful. Healthcare professionals working with individuals who will have radiotherapy following a breast cancer diagnosis should consider using this site to support patient preparation.

										</p>

										<Link to="resources">
											<button className="button is-large is-size-4">View resources</button>
										</Link>
									</Animated>
							
								</div>
									
							</div>

						
    					</div>
  					</div>
				</section>



			</Fragment>
				);
    }
}

const styles = {
	heroBody : {
		backgroundSize : "cover",
		backgroundPosition : "bottom"
	},
	titleTextTop : {
		marginBottom : 40
	},
	titleText : {
		fontSize: 54,
		background: "rgba(0,0,0,.6)",
		display: "inline-block",
		padding: "10px 15px"
	},
	subtitleText : {
		fontSize: 25,
		backgroundColor: "rgba(186, 0, 71, 0.80)",
		lineHeight: "60px",
		padding: 7
	},
	getStartedBody : {
		padding:100
	},
	reachOverButton : {
		marginTop: 30
	},
	getStartedButton : {
		paddingLeft : 50,
		paddingRight : 50,
		height:60,
		borderRadius:100,
		transition: "all 300ms ease",
		fontSize: 20
	},



};

export default Home