import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import ResourceModal from "./ResourceModal";

class Resource extends Component {
	constructor(props){
		super(props);

		this.state = {
			isActive : false
		}
	}
	render() {
		const {resource, onOpen} = this.props;
		const {isActive} = this.state;

		return (
			<Fragment>
				<div
					className="box resource has-badge-rounded has-badge-left"
					data-badge={this.props.order}
					style={styles.box}>

					<article className="media" style={styles.media}>

						<div className="media-content">
							<div className="content">
								<a
									href={this.props.link}
									onClick={() => {
										if(resource) onOpen(resource)
									}}
									style={styles.title}>
									{this.props.title}
								</a>

								{this.props.description ? (
									<p style={styles.description}>
										{this.props.description}
									</p>
								) : (<div/>)}
							</div>
						</div>

					</article>
				</div>
				{resource && isActive ? (
					<ResourceModal resource={resource} isActive={isActive} onClose={() => this.setState({isActive : false})}/>
				) : (<div/>)}
			</Fragment>

		)
	}
}

const styles = {
	title : {
		fontWeight: 400,
		fontSize: 17,
		color: "#7caeff"
	},
	description : {
		marginTop: 10,
		// color: "rgb(200,200,200)"
	},
	box : {
		borderRadius: 0,
		// backgroundColor: "rgba(0,0,0,0.3)"
	},
	media : {
		padding: "20px 0 20px 20px"
	},
	mediaLeft : {
		marginRight: 40
	}
};

export default Resource