
import React, {Component, Fragment} from 'react';
import {Header} from "../Header";
import TeamMember from "../elements/TeamMember";
import YouTube from "react-youtube-embed";

class Home extends Component{
	render(){
		return (
			<Fragment>
				<Header absolute/>
				<section style={styles.top} className="hero is-small is-primary is-bold">

					<div className="hero-body" style={styles.getStartedBody}>
						<div className="columns">
							<div className="column">
								<div className="container">

									<div className="columns">
										<div className="column is-10-fullhd is-offset-1-fullhd">
										<div class="box" style={styles.box}>
										<div className="columns">
											<div className="column">
												<YouTube id='kn79qb4MOTo' />
											</div>
										</div>
										<div className="columns">
											<div className="column">
												<h4 style={styles.teamText}>
													Coming for Radiotherapy
												</h4>
												<p
													style={styles.teamTextSubtitle}>
													This video clip shows what will happen when you attend for your first appointment in the CT scanning room. This is the first part of your radiotherapy planning.
													<br/><br/>
													In this video we have tried to give some examples of what the machine may look like and also the position you will be asked to lie in.
													<br/><br/>
													The machines may look slightly different to the ones shown in this video as different hospitals may use different machine manufacturers. You will also notice that the patient in the video is dressed in a special modesty gown for the purposes of the video. You are likely to be positioned naked from the waist upwards for your radiotherapy planning and treatment, or you may be asked to wear a specially designed bra.
												</p>
											</div>
										</div>
										</div>
									</div>
									</div>



								</div>
							</div>
						</div>
					</div>
				</section>

			</Fragment>
		)
	}
}

const styles={
	top : {
		paddingTop: 150,
		paddingBottom: 100
	},
	teamText : {
		fontSize: 35,
		fontWeight: 200,
		marginBottom: 20
	},
	teamTextSubtitle : {
		marginBottom: 0,
		fontWeight: 200,
		fontSize: 19
	},

	partners : {
		backgroundColor: "#ffffff"
	},
	box : {
		padding: 40,
		borderRadius: 0
	}
};

export default Home