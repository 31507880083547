import React, {Fragment, Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import { Navbar } from "react-bulma-components";

const Item = (props) => {
	const path = ('/'+window.location.pathname.substring(1).replace(/[^\w+].*$/,''));
	return (
		<Link className={`navbar-item ${path === props.to ? "is-active" : ""}`} to={props.to}>{props.children}</Link>
	)
}

export class Header extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false
		};
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}


	render() {
		const { isOpen } = this.state;
		const { absolute } = this.props;

		return (
			<nav
				className={`
					navbar
					${isOpen ? "is-active" : ""}
					// ${absolute && !isOpen ? "is-absolute" : ""}								
				`}
				role="navigation"
				aria-label="main navigation"
			>
					<div className="container">
						<div className="navbar-brand">
							<Item to="/">
								<img alt="Logo" src={"/images/logo.png"} style={{maxWidth: 150, maxHeight: 300}} />				
							</Item>
							<a
								role="button"
								className={`navbar-burger ${isOpen ? "is-active" : ""}`}
								onClick={() => this.setState({
									isOpen: !isOpen
								})}
								aria-label="menu"
								aria-expanded="false"
							>
								<span aria-hidden="true"/>
								<span aria-hidden="true"/>
								<span aria-hidden="true"/>
							</a>
						</div>

						<div className={`navbar-menu ${isOpen ? "is-active" : ""}`}>
							<Navbar.Container position="end">
								<Item to="/">Home</Item>
								<Item to="/resources" className="navbar-item">Resources</Item>
								<Item to="/faq" className="navbar-item">FAQ</Item>
								<Item to="/about" className="navbar-item">About us</Item>
							</Navbar.Container>
						</div>
					</div>
			</nav>
		);
	}
}



export default withRouter(Header)